.vertical-text {
    position: absolute;

    h1, h2, h3, h4, h5, h6 {
        font-size: 10rem;
        font-weight: 800;
        opacity: 0.1;
        white-space: nowrap;
        margin: 0;
        user-select: none;
    }

    &.left-aligned {
        left: 0;
        writing-mode: tb-rl;
        transform: rotate(-180deg);
        text-orientation: mixed;
    }

    &.right-aligned {
        right: 0;
        writing-mode: vertical-rl;
        text-orientation: mixed;
    }
}