section {
    min-height: 100vh;
    position: relative;

    .section-hero {
        width: 100%;
        height: 100%;

        top: 0;
        left: 0;
        
        position: absolute;
    }

    .section-title {
        h2 {
            font-size: 4rem;
        }

        p {
            font-size: 1.25rem;
        }
    }
}