section#home {
    > * {
        padding-top: 8rem;
    }

    .section-body {
        padding-top: 10%;

        h1 {
            font-size: 5rem;
            font-weight: 800;
        } 

        p {
            font-size: 1.5rem;
        }
    }
}