#webgl {
    width: 100%;
    max-width: 100%;

    height: 100%;

    top: 0;
    left: 0;

    position: absolute;
    overflow: hidden;
}